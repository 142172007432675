import React from "react";
import {
      BankDetails,
      BorderContainer,
      Container,
      FirstSection,
      Header,
      Info,
      InfoBlock,
      InvoiceContainer,
      Label,
      LeftSection,
      RightInfoBlock,
      RightSection,
      SecondInfo,
      SecondInfoBlock,
      SecondSection,
      Section,
      Separator,
      SignatureSection,
      Table,
      Td,
      Th,
      Title,
      Value,
} from "./style";
import { DashboardContainer } from "../layouts/style";

const InvoiceTicket = () => {
      return (
            <>
                  <DashboardContainer>
                        <InvoiceContainer>
                              <BorderContainer>
                                    <Header>Invoice (Original)</Header>
                                    <FirstSection>
                                          <LeftSection>
                                                <Title>
                                                      Etrav Tech Limited
                                                </Title>
                                                <InfoBlock>
                                                      201, Maxheal House Bangur
                                                      Nagar, Near Ayyapa Temple,{" "}
                                                      <br /> Mumbai, MAHARASHTRA
                                                      - 400090
                                                </InfoBlock>
                                                <InfoBlock>
                                                      Phone: 02269899999
                                                </InfoBlock>
                                                <InfoBlock>
                                                      Email: support@etrav.in
                                                </InfoBlock>
                                                <InfoBlock>
                                                      Web: Etrav.in
                                                </InfoBlock>
                                          </LeftSection>

                                          <RightSection>
                                                <RightInfoBlock>
                                                      <Label>GST Number</Label>
                                                      <Separator>:</Separator>
                                                      <Value>
                                                            DN/FEB-25/1367584
                                                      </Value>
                                                </RightInfoBlock>
                                                <RightInfoBlock>
                                                      <Label>Date </Label>
                                                      <Separator>:</Separator>
                                                      <Value>
                                                            04/02/2025 12:56:13
                                                      </Value>
                                                </RightInfoBlock>
                                                <RightInfoBlock>
                                                      <Label>GST Number</Label>
                                                      <Separator>:</Separator>
                                                      <Value>
                                                            27AAECR8945A1ZB
                                                      </Value>
                                                </RightInfoBlock>
                                                <RightInfoBlock>
                                                      <Label>PAN Number</Label>
                                                      <Separator>:</Separator>
                                                      <Value>AAECR8945A</Value>
                                                </RightInfoBlock>
                                                <RightInfoBlock>
                                                      <Label>SAC Code</Label>
                                                      <Separator>:</Separator>
                                                      <Value> 99855</Value>
                                                </RightInfoBlock>
                                                <RightInfoBlock>
                                                      <Label>
                                                            Reference Number
                                                      </Label>
                                                      <Separator>:</Separator>
                                                      <Value>FBB8FRTU</Value>
                                                </RightInfoBlock>
                                          </RightSection>
                                    </FirstSection>

                                    <SecondSection>
                                          <Title>Billed To</Title>
                                          <SecondInfo>
                                                <Title>
                                                      PAREEK TOURS AND TRAVELS -
                                                      ( RJAE090893-DINESH KUMAR
                                                      PAREEK)
                                                </Title>
                                                <SecondInfoBlock>
                                                      <p>Address</p>
                                                      <strong>:</strong>
                                                      <span>
                                                            MAIN MARKET NEAR
                                                            CLOCK TOWER,-, -,
                                                            Sardarshahar
                                                      </span>
                                                </SecondInfoBlock>
                                                <SecondInfoBlock>
                                                      <p>Email </p>
                                                      <strong>:</strong>
                                                      <span>
                                                            PAREEKWORLDWIDETRAVELS@GMAIL.COM
                                                      </span>
                                                </SecondInfoBlock>
                                                <SecondInfoBlock>
                                                      <p>PAN Number </p>
                                                      <strong>:</strong>
                                                      <span>AKLPP7916P</span>
                                                </SecondInfoBlock>
                                          </SecondInfo>
                                    </SecondSection>

                                    <SecondSection>
                                          <Table>
                                                <thead>
                                                      <tr>
                                                            <Th>Description</Th>
                                                            <Th>
                                                                  Supplier Ref
                                                                  No.
                                                            </Th>
                                                            <Th>Basic</Th>
                                                            <Th>Taxes</Th>
                                                            <Th>SSR</Th>
                                                            <Th>
                                                                  Ticket Value{" "}
                                                                  <br /> (INR)
                                                            </Th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      <tr>
                                                            <Td>
                                                                  <p>
                                                                        Onward
                                                                        Journey:
                                                                        JAI-DMK
                                                                        | FD-131
                                                                        |
                                                                        11/02/2025
                                                                        23:10:00{" "}
                                                                        <br />{" "}
                                                                        12/02/2025
                                                                        04:30:00
                                                                  </p>
                                                                  <p>
                                                                        Return
                                                                        Journey:
                                                                        DMK-JAI
                                                                        | FD-130
                                                                        |
                                                                        19/02/2025
                                                                        19:10:00{" "}
                                                                        <br />{" "}
                                                                        19/02/2025
                                                                        22:10:00
                                                                  </p>
                                                                  <h3>
                                                                        Mr
                                                                        VIRENDRA
                                                                        PRATAP
                                                                        SINGH
                                                                        CHARAN |
                                                                        GFPT3ERTU1
                                                                  </h3>
                                                            </Td>
                                                            <Td>GFPT3E</Td>
                                                            <Td>8801.00</Td>
                                                            <Td>10967.00</Td>
                                                            <Td>4628.00</Td>
                                                            <Td>24396.00</Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  <div className="text-align">
                                                                        (+)
                                                                        Transaction
                                                                        Fee
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  {" "}
                                                                  <div className="text-align">
                                                                        100.00
                                                                  </div>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  <div className="text-align">
                                                                        (-)
                                                                        Commission{" "}
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  {" "}
                                                                  <div className="text-align">
                                                                        0.00
                                                                  </div>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  <div className="text-align">
                                                                        (+) TDS
                                                                        Deducted{" "}
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  <div className="text-align">
                                                                        0.00
                                                                  </div>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  {" "}
                                                                  <div className="text-align">
                                                                        (+) GST{" "}
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  <div className="text-align">
                                                                        18.00
                                                                  </div>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  {" "}
                                                                  <div className="text-align">
                                                                        (+) IGST
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  <div className="text-align">
                                                                        0.00
                                                                  </div>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  {" "}
                                                                  <div className="text-align">
                                                                        <strong>
                                                                              Total
                                                                        </strong>
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  <strong>
                                                                        <div className="text-align">
                                                                              24514.00
                                                                        </div>
                                                                  </strong>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="6">
                                                                  {" "}
                                                                  <strong>
                                                                        Amount
                                                                        in
                                                                        Words:
                                                                        Twenty
                                                                        Four
                                                                        Thousand
                                                                        Five
                                                                        Hundred
                                                                        Fourteen
                                                                        Only
                                                                  </strong>{" "}
                                                            </Td>
                                                      </tr>
                                                </tbody>
                                          </Table>
                                    </SecondSection>
                                    <hr />

                                    <Container>
                                          <BankDetails>
                                                <h3>Bank Details</h3>

                                                <Info>
                                                      Name:{" "}
                                                      <span>
                                                            Etrav Tech Limited
                                                      </span>
                                                </Info>
                                                <Info>
                                                      Bank Name:{" "}
                                                      <span>
                                                            ICICI Bank Ltd
                                                      </span>
                                                </Info>
                                                <Info>
                                                      Branch Name:{" "}
                                                      <span>MALAD (W)</span>
                                                </Info>
                                                <Info>
                                                      IFSC Code:{" "}
                                                      <span>ICIC0001517</span>
                                                </Info>
                                                <Info>
                                                      Account No:{" "}
                                                      <span>19350603174</span>
                                                </Info>
                                          </BankDetails>
                                          <SignatureSection>
                                                <div>
                                                      For Etrav Tech Limited
                                                </div>
                                                <div
                                                      style={{
                                                            color: "#303030",
                                                      }}
                                                >
                                                      Authorised Signatory
                                                </div>
                                          </SignatureSection>
                                    </Container>
                              </BorderContainer>
                              <p className="note">Note:</p>
                              <span className="content">
                                    This is a computer generated invoice.
                                    Signature not required.
                              </span>
                        </InvoiceContainer>
                  </DashboardContainer>
            </>
      );
};

export default InvoiceTicket;
