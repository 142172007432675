import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { handleHotelCommissionTotal } from "../../hooks/hotelCommission";
import { DashboardContainer } from "../layouts/style";
import HotelTicketLayout from "../../frontend/hotel_confirmations/HotelTicketLayout";
import { ButtonContainer, ButtonContent, ExtraContent } from "../flights/style";
import { FaRegFilePdf } from "react-icons/fa";
import { IoPricetagOutline } from "react-icons/io5";
import { Checkbox, FormControlLabel, RadioGroup } from "@mui/material";
import {
      FlightModal,
      FlightPriceItem,
} from "../../frontend/flight_confirmations/FlightConfirmModal/style";
import { FormControl, ModalBody, ModalHeader } from "react-bootstrap";
import CommonButton from "../../components/Common/CommonButton";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";

const HotelTicketView = () => {
      const [show, setShow] = useState(false);
      const [markupPrice, setMarkupPrice] = useState(0);
      const [withPriceStatus, setWithPriceStatus] = useState(true);
      const [withInfo, setWithInfo] = useState(true);
      const [grandTotal, setGrandTotal] = useState(0);

      const [loading, setLoading] = useState(false);
      const { bookingId } = useParams();
      const [loadingPdf, setLoadingPdf] = useState(false);

      const [agentOrder, setAgentOrder] = useState([]);
      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [priceInfos, setPriceInfos] = useState([]);
      const [totalAmount, setTotalAmount] = useState([]);
      const [searchQuery, setSearchQuery] = useState([]);
      const [profile, setProfile] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const handleShow = () => {
            setLoading(true);
            setTimeout(() => {
                  setShow(true);
                  setLoading(false);
            }, 1000);
      };

      const handleClose = () => setShow(false);

      const handleTotalPrice = ({ prices, commissionsAmount }) => {
            const totalAmt = handleHotelCommissionTotal({
                  prices: prices,
                  commissionsAmount: commissionsAmount,
            });
            return totalAmt;
      };

      const loadData = async () => {
            setLoading(true);

            const response = await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings/${bookingId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        toast.error(error.message);
                  });

            if (response?.data?.result === "success") {
                  setAgentOrder(response.data.agent);
                  setProfile(response.data.agentDetail);

                  await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/hotel/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.status?.success === true) {
                                    setOrder(response.data.order);
                                    setTripInfos(
                                          response.data.itemInfos?.HOTEL?.hInfo
                                    );
                                    setPriceInfos(
                                          response.data.itemInfos?.HOTEL?.hInfo
                                                ?.ops[0]
                                    );
                                    setSearchQuery(
                                          response.data.itemInfos?.HOTEL?.query
                                    );
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      useEffect(() => {
            setTotalAmount(
                  handleTotalPrice({
                        prices: priceInfos?.ris,
                        commissionsAmount: agentOrder?.commission,
                  })
            );
      }, [priceInfos, agentOrder]);

      const handlePrintPdf = async () => {
            setLoadingPdf(true);
            try {
                  const name = `${tripInfos?.name}-${bookingId}`;
                  const url = `${process.env.REACT_APP_DUMMY_KEY}/hotels/confirmation/${bookingId}/pdf`;

                  const response = await axios.get(
                        `https://apipdf.hamroappliances.com/generate-pdf`,
                        {
                              params: {
                                    url,
                                    name,
                                    markupPrice,
                                    withPriceStatus,
                                    withInfo,
                              },
                              responseType: "blob", // Important: tells axios to handle binary data
                        }
                  );

                  // Create a blob from the response data
                  const pdfBlob = new Blob([response.data], {
                        type: "application/pdf",
                  });

                  // Create a link element
                  const downloadLink = document.createElement("a");

                  // Set the link's href to a URL created from the blob
                  downloadLink.href = URL.createObjectURL(pdfBlob);

                  // Set the download attribute with the file name
                  downloadLink.download = `${name || "generated"}.pdf`;

                  // Append the link to the document body
                  document.body.appendChild(downloadLink);

                  // Programmatically click the link to trigger the download
                  downloadLink.click();

                  // Remove the link from the document
                  document.body.removeChild(downloadLink);
            } catch (error) {
                  console.error("Error generating or downloading PDF:", error);
                  alert("Failed to download PDF");
            } finally {
                  setLoadingPdf(false);
                  handleClose();
            }
      };

      const handleInput = (event) => {
            const inputValue = parseFloat(event.target.value) || 0;
            setMarkupPrice(inputValue);
      };

      useEffect(() => {
            setGrandTotal(totalAmount + markupPrice);
      }, [markupPrice]);

      return (
            <>
                  <DashboardContainer flexDirection="column">
                        <HotelTicketLayout
                              tripInfos={tripInfos}
                              priceInfos={priceInfos}
                              bookingId={bookingId}
                              order={order}
                              searchQuery={searchQuery}
                              totalAmount={totalAmount}
                              agentOrder={agentOrder}
                              markupPrice={markupPrice}
                              profile={profile}
                              withInfo={withInfo}
                              currentPriceStatus={withPriceStatus}
                        />
                        <ButtonContainer>
                              <ButtonContent>
                                    <button
                                          className="btn-print"
                                          onClick={handlePrintPdf}
                                          disabled={loadingPdf}
                                    >
                                          {loadingPdf ? (
                                                "Generating PDF..."
                                          ) : (
                                                <div className="d-flex align-items-center gap-1">
                                                      <FaRegFilePdf />
                                                      Download PDF
                                                </div>
                                          )}
                                    </button>
                                    <button
                                          className="btn-print"
                                          onClick={handleShow}
                                    >
                                          <div className="d-flex align-items-center gap-1">
                                                <IoPricetagOutline />
                                                Add Markup
                                          </div>
                                    </button>
                                    <ExtraContent>
                                          <RadioGroup>
                                                <FormControlLabel
                                                      control={
                                                            <Checkbox
                                                                  value={
                                                                        !withPriceStatus
                                                                  }
                                                                  checked={
                                                                        withPriceStatus ===
                                                                        false
                                                                  }
                                                                  onChange={() =>
                                                                        setWithPriceStatus(
                                                                              !withPriceStatus
                                                                        )
                                                                  }
                                                            />
                                                      }
                                                      label="Hide Fare"
                                                />
                                          </RadioGroup>
                                          <RadioGroup>
                                                <FormControlLabel
                                                      control={
                                                            <Checkbox
                                                                  value={
                                                                        !withInfo
                                                                  }
                                                                  checked={
                                                                        withInfo ===
                                                                        false
                                                                  }
                                                                  onChange={() =>
                                                                        setWithInfo(
                                                                              !withInfo
                                                                        )
                                                                  }
                                                            />
                                                      }
                                                      label="Hide Agency Detail"
                                                />
                                          </RadioGroup>
                                    </ExtraContent>
                              </ButtonContent>
                        </ButtonContainer>
                  </DashboardContainer>
                  <FlightModal
                        backdrop="static"
                        show={show}
                        top="18px"
                        onHide={handleClose}
                  >
                        <ModalHeader closeButton>Add Markup</ModalHeader>
                        <ModalBody>
                              {withPriceStatus && (
                                    <>
                                          <FlightPriceItem className="">
                                                <div>Total Net Price</div>
                                                <div>
                                                      ₹{" "}
                                                      {convertAmount(
                                                            convertFloat(
                                                                  totalAmount
                                                            )
                                                      )}
                                                </div>
                                          </FlightPriceItem>
                                          <FlightPriceItem>
                                                <div>Enter your Markup</div>
                                                <FormControl
                                                      type="text"
                                                      onChange={handleInput}
                                                />
                                          </FlightPriceItem>
                                          <FlightPriceItem className="border-top border-bottom py-2">
                                                <div className="grand__total">
                                                      Total Price
                                                </div>
                                                <div className="grand__total">
                                                      ₹{" "}
                                                      {convertAmount(
                                                            grandTotal
                                                      )}
                                                </div>
                                          </FlightPriceItem>
                                    </>
                              )}
                              <CommonButton
                                    button={true}
                                    type="button"
                                    classes={"mt-3"}
                                    handleClick={handlePrintPdf}
                                    loading={loadingPdf}
                                    justifyContent="center"
                                    title="Download"
                              />
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default HotelTicketView;
