import React, { useEffect, useState } from "react";
import { FormControl, ModalBody, ModalHeader } from "react-bootstrap";
import CommonButton from "../../../components/Common/CommonButton";
import { FlightModal, FlightPriceItem } from "./style";
import {
      Checkbox,
      FormControlLabel,
      Radio,
      RadioGroup,
      Skeleton,
} from "@mui/material";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";
import axios from "axios";

const FlightConfirmModal = ({
      totalPriceInfo,
      agentOrder,
      loadingData,
      bookingId,
      tripInfos,
}) => {
      const [loading, setLoading] = useState(false);
      const [show, setShow] = useState(false);
      const [loadingPdf, setLoadingPdf] = useState(false);
      const [withPriceStatus, setWithPriceStatus] = useState(true);
      const [markupPrice, setMarkupPrice] = useState(0);
      const [withInfo, setWithInfo] = useState(true);
      const [grandTotal, setGrandTotal] = useState(0);

      const handleShow = () => {
            setLoading(true);
            setTimeout(() => {
                  setShow(true);
                  setLoading(false);
            }, 1000);
      };

      const handleClose = () => setShow(false);

      const handleChange = () => setWithPriceStatus(!withPriceStatus);

      const handleInput = (event) => {
            const inputValue = parseFloat(event.target.value) || 0;
            setMarkupPrice(inputValue);
      };

      const totalAmount = () => {
            let totalAmount = totalPriceInfo?.fC?.TF || 0;
            if (agentOrder?.seriesFareStatus === 1) {
                  totalAmount += agentOrder.commission || 0;
            }
            return totalAmount;
      };

      useEffect(() => {
            setGrandTotal(totalAmount() + markupPrice);
      }, [markupPrice, totalPriceInfo]);

      useEffect(() => {
            setMarkupPrice(0);
      }, [withPriceStatus]);

      const handlePrintPdf = async () => {
            setLoadingPdf(true);

            try {
                  const name = `${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}`;
                  const url = `${process.env.REACT_APP_DUMMY_KEY}/flights/confirmation/${bookingId}/pdf`;

                  const response = await axios.get(
                        `https://apipdf.hamroappliances.com/generate-pdf`,
                        {
                              params: {
                                    url,
                                    name,
                                    markupPrice,
                                    withPriceStatus,
                                    withInfo,
                              },
                              responseType: "blob", // Important: tells axios to handle binary data
                        }
                  );

                  // Create a blob from the response data
                  const pdfBlob = new Blob([response.data], {
                        type: "application/pdf",
                  });

                  // Create a link element
                  const downloadLink = document.createElement("a");

                  // Set the link's href to a URL created from the blob
                  downloadLink.href = URL.createObjectURL(pdfBlob);

                  // Set the download attribute with the file name
                  downloadLink.download = `${name || "generated"}.pdf`;

                  // Append the link to the document body
                  document.body.appendChild(downloadLink);

                  // Programmatically click the link to trigger the download
                  downloadLink.click();

                  // Remove the link from the document
                  document.body.removeChild(downloadLink);
            } catch (error) {
                  console.error("Error generating or downloading PDF:", error);
                  alert("Failed to download PDF");
            } finally {
                  setLoadingPdf(false);
            }
      };

      return (
            <>
                  {!loadingData ? (
                        <CommonButton
                              handleClick={handleShow}
                              loading={loading}
                              button={true}
                              type="button"
                              title="Download Voucher"
                        />
                  ) : (
                        <Skeleton
                              height="40px"
                              width={"160px"}
                              style={{
                                    transform: "scale(1)",
                              }}
                              className="rounded-pill"
                        />
                  )}
                  <FlightModal
                        backdrop="static"
                        show={show}
                        top="18px"
                        onHide={handleClose}
                  >
                        <ModalHeader closeButton>Download Voucher</ModalHeader>
                        <ModalBody>
                              <RadioGroup
                                    row
                                    aria-labelledby="price-option-label"
                              >
                                    <FormControlLabel
                                          control={
                                                <Radio
                                                      value={true}
                                                      checked={withPriceStatus}
                                                      onChange={handleChange}
                                                />
                                          }
                                          label="With Price"
                                    />
                                    <FormControlLabel
                                          control={
                                                <Radio
                                                      value={false}
                                                      checked={
                                                            withPriceStatus ===
                                                            false
                                                      }
                                                      onChange={handleChange}
                                                />
                                          }
                                          label="Without Price"
                                    />
                                    <FormControlLabel
                                          control={
                                                <Checkbox
                                                      value={!withInfo}
                                                      checked={
                                                            withInfo === false
                                                      }
                                                      onChange={() =>
                                                            setWithInfo(
                                                                  !withInfo
                                                            )
                                                      }
                                                />
                                          }
                                          label="Hide Agency Detail"
                                    />
                              </RadioGroup>
                              {withPriceStatus && (
                                    <>
                                          <FlightPriceItem className="mt-2 border-top pt-3">
                                                <div>Total Net Price</div>
                                                <div>
                                                      ₹{" "}
                                                      {convertAmount(
                                                            convertFloat(
                                                                  totalAmount()
                                                            )
                                                      )}
                                                </div>
                                          </FlightPriceItem>
                                          <FlightPriceItem>
                                                <div>Enter your Markup</div>
                                                <FormControl
                                                      type="text"
                                                      onChange={handleInput}
                                                />
                                          </FlightPriceItem>
                                          <FlightPriceItem className="border-top border-bottom py-2">
                                                <div className="grand__total">
                                                      Total Price
                                                </div>
                                                <div className="grand__total">
                                                      ₹{" "}
                                                      {convertAmount(
                                                            grandTotal
                                                      )}
                                                </div>
                                          </FlightPriceItem>
                                    </>
                              )}
                              <CommonButton
                                    button={true}
                                    type="button"
                                    classes={"mt-3"}
                                    handleClick={handlePrintPdf}
                                    loading={loadingPdf}
                                    justifyContent="center"
                                    title="Download"
                              />
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default FlightConfirmModal;
