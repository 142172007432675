import { Route } from "react-router-dom";
import { HotelTicketView } from "../account/hotels";
import HotelConfirmationPage from "../frontend/hotel_confirmations";
import HotelPaymentConfirmation from "../frontend/hotel_payment_confirmation";
import HotelReviewPage from "../frontend/hotel_reviews";
import { HotelDetail, HotelPage, HotelSearch } from "../frontend/hotels";

export const hotelMange = [
      // Hotel Page
      <Route path="/hotel" element={<HotelPage />} />,
      <Route path="/hotel/search" element={<HotelSearch />} />,
      <Route path="/hotel/:hotelId" element={<HotelDetail />} />,
      <Route
            path="/hotel/:optionId/:hotelId/:hOptionId"
            element={<HotelReviewPage />}
      />,

      // Hotel Confirmation
      <Route
            path="/hotels/confirmation/:bookingId"
            element={<HotelConfirmationPage />}
      />,
      <Route
            path="/hotels/payment/confirmation/:topupId"
            element={<HotelPaymentConfirmation />}
      />,
      <Route
            path="/hotels/confirmation/:bookingId/view"
            element={<HotelTicketView />}
      />,
];
