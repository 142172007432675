import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import {
      BookingItemBody,
      BookingItemButton,
      BookingItemCard,
      BookingItemContainer,
      BookingItemFooter,
      BookingItemHeader,
      BookingItemPnrBox,
} from "./style";
import { convertDate } from "../../../hooks/CurrentData";
import FlightTableFilter from "./FlightTableFilter";

const FlightTable = () => {
      const [loading, setLoading] = useState(false);
      const [bookings, setBookings] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings?order_type=AIR`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBookings(response.data.bookings);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <FlightTableFilter
                        setLoading={setLoading}
                        setBookings={setBookings}
                  />
                  <BookingItemContainer>
                        {!loading ? (
                              bookings?.length > 0 ? (
                                    bookings?.map((item, index) => (
                                          <>
                                                <BookingItemCard key={index}>
                                                      <BookingItemHeader>
                                                            <div className="first__width">
                                                                  {item?.name ||
                                                                        "-"}
                                                            </div>
                                                            <div className="second__width">
                                                                  Ticket
                                                                  Confirmed
                                                            </div>
                                                            <div className="third__width"></div>
                                                            <div className="third__width">
                                                                  Status
                                                            </div>
                                                      </BookingItemHeader>
                                                      <BookingItemBody>
                                                            <div className="first__width">
                                                                  1.{" "}
                                                                  {item?.name ||
                                                                        "-"}
                                                            </div>
                                                            <div className="second__width">
                                                                  <div>
                                                                        {item?.summary ||
                                                                              "-"}
                                                                  </div>
                                                                  <div>
                                                                        <div className="fw-bold">
                                                                              Booked
                                                                              On
                                                                              {
                                                                                    ": "
                                                                              }
                                                                        </div>

                                                                        <div className="text-muted">
                                                                              {item?.created_at
                                                                                    ? convertDate(
                                                                                            item?.created_at
                                                                                      )
                                                                                    : "-"}
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="third__width">
                                                                  <BookingItemPnrBox>
                                                                        <div>
                                                                              <div className="fw-bold">
                                                                                    PNR
                                                                                    {
                                                                                          ": "
                                                                                    }
                                                                              </div>

                                                                              <div className="text-muted">
                                                                                    {item?.pnr_number ||
                                                                                          "-"}
                                                                              </div>
                                                                        </div>
                                                                  </BookingItemPnrBox>
                                                            </div>
                                                            <div
                                                                  className={`third__width ${
                                                                        item?.status ===
                                                                        "Success"
                                                                              ? "text-success"
                                                                              : item?.status ===
                                                                                "Failed"
                                                                              ? "text-danger"
                                                                              : ""
                                                                  }`}
                                                            >
                                                                  {item?.status}
                                                            </div>
                                                      </BookingItemBody>
                                                      <BookingItemFooter className="align-items-center">
                                                            <BookingItemPnrBox
                                                                  boxShadow="none"
                                                                  className="border-0 p-0"
                                                            >
                                                                  <div>
                                                                        <div className="fw-bold">
                                                                              Reference
                                                                              No
                                                                              {
                                                                                    ": "
                                                                              }
                                                                              <span className="text-muted">
                                                                                    {item?.booking_id ||
                                                                                          "-"}
                                                                              </span>
                                                                        </div>
                                                                  </div>
                                                            </BookingItemPnrBox>
                                                            <BookingItemFooter>
                                                                  <BookingItemButton
                                                                        to={`/flights/confirmation/${item?.booking_id}`}
                                                                        target="_blank"
                                                                  >
                                                                        Open
                                                                  </BookingItemButton>
                                                                  <BookingItemButton>
                                                                        Invoice
                                                                  </BookingItemButton>
                                                                  <BookingItemButton
                                                                        target="_blank"
                                                                        to={`/flights/invoice/${item?.booking_id}/view`}
                                                                  >
                                                                        Ticket
                                                                  </BookingItemButton>
                                                                  <BookingItemButton className="bg-danger">
                                                                        Raise
                                                                        Request
                                                                  </BookingItemButton>
                                                            </BookingItemFooter>
                                                      </BookingItemFooter>
                                                </BookingItemCard>
                                          </>
                                    ))
                              ) : (
                                    <>
                                          <div className="text-center">
                                                <p>No Booking Found</p>
                                          </div>
                                    </>
                              )
                        ) : (
                              <>
                                    <div className="text-center w-100">
                                          <Spinner />
                                    </div>
                              </>
                        )}
                  </BookingItemContainer>
            </>
      );
};

export default FlightTable;
