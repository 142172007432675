import styled from "styled-components";

export const ButtonContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 4rem;
`;

export const ButtonContent = styled.div`
      display: flex;
      align-items: center;
      padding: 8px 20px;
      gap: 20px;
      background-color: ${(props) => props.theme.white};
      border-radius: 5px;
      border: 1px solid #ccc;

      .btn-print {
            background-color: ${(props) => props.theme.primary};
            border: 1px solid #f4f4f4;
            color: #f4f4f4;
            transition: all 0.3s ease-in-out;

            &:hover {
                  background-color: #142a5a;
            }
      }
`;

export const ExtraContent = styled.div`
      display: flex;
      align-items: center;
`;
