import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { DashboardContainer } from "../layouts/style";
import TicketLayout from "../../frontend/booking/TicketLayout";
import { ButtonContainer, ButtonContent, ExtraContent } from "./style";
import { FaRegFilePdf } from "react-icons/fa";
import { IoPricetagOutline } from "react-icons/io5";
import { Checkbox, FormControlLabel, RadioGroup } from "@mui/material";
import {
      FlightModal,
      FlightPriceItem,
} from "../../frontend/flight_confirmations/FlightConfirmModal/style";
import { FormControl, ModalBody, ModalHeader } from "react-bootstrap";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";
import CommonButton from "../../components/Common/CommonButton";

const FlightTicketView = () => {
      const [show, setShow] = useState(false);
      const [markupPrice, setMarkupPrice] = useState(0);
      const [withPriceStatus, setWithPriceStatus] = useState(true);
      const [withInfo, setWithInfo] = useState(true);
      const [grandTotal, setGrandTotal] = useState(0);

      const [loading, setLoading] = useState(false);
      const { bookingId } = useParams();
      const [loadingPdf, setLoadingPdf] = useState(false);
      const [profile, setProfile] = useState([]);

      const [agentOrder, setAgentOrder] = useState([]);
      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [totalPriceInfo, setTotalPriceInfo] = useState([]);
      const [travellerInfos, setTravellerInfos] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const handleShow = () => {
            setLoading(true);
            setTimeout(() => {
                  setShow(true);
                  setLoading(false);
            }, 1000);
      };

      const handleClose = () => setShow(false);

      // Load booking data
      const loadData = async () => {
            try {
                  setLoading(true);
                  const response = await axios.get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings/${bookingId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  );

                  if (response?.data?.result === "success") {
                        setAgentOrder(response.data.agent);
                        setProfile(response.data.agentDetail);

                        const flightDetailsResponse = await axios.post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        );

                        if (flightDetailsResponse?.data?.status?.success) {
                              setOrder(flightDetailsResponse.data.order);
                              setTripInfos(
                                    flightDetailsResponse.data.itemInfos?.AIR
                                          ?.tripInfos || []
                              );
                              setTotalPriceInfo(
                                    flightDetailsResponse.data.itemInfos?.AIR
                                          ?.totalPriceInfo?.totalFareDetail ||
                                          {}
                              );
                              setTravellerInfos(
                                    flightDetailsResponse.data.itemInfos?.AIR
                                          ?.travellerInfos || []
                              );
                        }
                  }
            } catch (error) {
                  toast.error(error.message);
            } finally {
                  setLoading(false);
            }
      };

      // Update booking data
      const updateData = async () => {
            if (!tripInfos.length || !travellerInfos.length) return;

            try {
                  const pnrNumber =
                        travellerInfos[0]?.pnrDetails[
                              `${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}`
                        ];

                  await axios.post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings/${bookingId}`,
                        {
                              pnr_number: pnrNumber,
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  );
            } catch (error) {
                  console.error("Error updating booking data:", error.message);
            }
      };

      useEffect(() => {
            loadData();
      }, []);

      useEffect(() => {
            updateData();
      }, [travellerInfos]);

      // Print PDF function
      const handlePrintPdf = async () => {
            setLoadingPdf(true);
            try {
                  const name = `${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}`;
                  const url = `${process.env.REACT_APP_DUMMY_KEY}/flights/confirmation/${bookingId}/pdf`;

                  const response = await axios.get(
                        `https://apipdf.hamroappliances.com/generate-pdf`,
                        {
                              params: {
                                    url,
                                    name,
                                    markupPrice,
                                    withPriceStatus,
                                    withInfo,
                              },
                              responseType: "blob", // Important: tells axios to handle binary data
                        }
                  );

                  // Create a blob from the response data
                  const pdfBlob = new Blob([response.data], {
                        type: "application/pdf",
                  });

                  // Create a link element
                  const downloadLink = document.createElement("a");

                  // Set the link's href to a URL created from the blob
                  downloadLink.href = URL.createObjectURL(pdfBlob);

                  // Set the download attribute with the file name
                  downloadLink.download = `${name || "generated"}.pdf`;

                  // Append the link to the document body
                  document.body.appendChild(downloadLink);

                  // Programmatically click the link to trigger the download
                  downloadLink.click();

                  // Remove the link from the document
                  document.body.removeChild(downloadLink);
            } catch (error) {
                  console.error("Error generating or downloading PDF:", error);
                  alert("Failed to download PDF");
            } finally {
                  setLoadingPdf(false);
                  handleClose();
            }
      };

      const handleInput = (event) => {
            const inputValue = parseFloat(event.target.value) || 0;
            setMarkupPrice(inputValue);
      };

      const totalAmount = () => {
            let totalAmount = totalPriceInfo?.fC?.TF || 0;
            if (agentOrder?.seriesFareStatus === 1) {
                  totalAmount += agentOrder.commission || 0;
            }
            return totalAmount;
      };

      useEffect(() => {
            setGrandTotal(totalAmount() + markupPrice);
      }, [markupPrice, totalPriceInfo]);

      return (
            <>
                  <DashboardContainer flexDirection="column" padding="120px">
                        {/* {!loading && ( */}
                        <>
                              <TicketLayout
                                    bookingId={bookingId}
                                    order={order}
                                    profile={profile}
                                    travellerInfos={travellerInfos}
                                    currentPriceStatus={withPriceStatus}
                                    tripInfos={tripInfos}
                                    totalPriceInfo={totalPriceInfo}
                                    markupPrice={markupPrice}
                                    agentOrder={agentOrder}
                                    withInfo={withInfo}
                                    borderShadow="none"
                              />
                              <ButtonContainer>
                                    <ButtonContent>
                                          <button
                                                className="btn-print"
                                                onClick={handlePrintPdf}
                                                disabled={loadingPdf}
                                          >
                                                {loadingPdf ? (
                                                      "Generating PDF..."
                                                ) : (
                                                      <div className="d-flex align-items-center gap-1">
                                                            <FaRegFilePdf />
                                                            Download PDF
                                                      </div>
                                                )}
                                          </button>
                                          <button
                                                className="btn-print"
                                                onClick={handleShow}
                                          >
                                                <div className="d-flex align-items-center gap-1">
                                                      <IoPricetagOutline />
                                                      Add Markup
                                                </div>
                                          </button>
                                          <ExtraContent>
                                                <RadioGroup>
                                                      <FormControlLabel
                                                            control={
                                                                  <Checkbox
                                                                        value={
                                                                              !withPriceStatus
                                                                        }
                                                                        checked={
                                                                              withPriceStatus ===
                                                                              false
                                                                        }
                                                                        onChange={() =>
                                                                              setWithPriceStatus(
                                                                                    !withPriceStatus
                                                                              )
                                                                        }
                                                                  />
                                                            }
                                                            label="Hide Fare"
                                                      />
                                                </RadioGroup>
                                                <RadioGroup>
                                                      <FormControlLabel
                                                            control={
                                                                  <Checkbox
                                                                        value={
                                                                              !withInfo
                                                                        }
                                                                        checked={
                                                                              withInfo ===
                                                                              false
                                                                        }
                                                                        onChange={() =>
                                                                              setWithInfo(
                                                                                    !withInfo
                                                                              )
                                                                        }
                                                                  />
                                                            }
                                                            label="Hide Agency Detail"
                                                      />
                                                </RadioGroup>
                                          </ExtraContent>
                                    </ButtonContent>
                              </ButtonContainer>
                        </>
                  </DashboardContainer>

                  <FlightModal
                        backdrop="static"
                        show={show}
                        top="18px"
                        onHide={handleClose}
                  >
                        <ModalHeader closeButton>Add Markup</ModalHeader>
                        <ModalBody>
                              {withPriceStatus && (
                                    <>
                                          <FlightPriceItem className="">
                                                <div>Total Net Price</div>
                                                <div>
                                                      ₹{" "}
                                                      {convertAmount(
                                                            convertFloat(
                                                                  totalAmount()
                                                            )
                                                      )}
                                                </div>
                                          </FlightPriceItem>
                                          <FlightPriceItem>
                                                <div>Enter your Markup</div>
                                                <FormControl
                                                      type="text"
                                                      onChange={handleInput}
                                                />
                                          </FlightPriceItem>
                                          <FlightPriceItem className="border-top border-bottom py-2">
                                                <div className="grand__total">
                                                      Total Price
                                                </div>
                                                <div className="grand__total">
                                                      ₹{" "}
                                                      {convertAmount(
                                                            grandTotal
                                                      )}
                                                </div>
                                          </FlightPriceItem>
                                    </>
                              )}
                              <CommonButton
                                    button={true}
                                    type="button"
                                    classes={"mt-3"}
                                    handleClick={handlePrintPdf}
                                    loading={loadingPdf}
                                    justifyContent="center"
                                    title="Download"
                              />
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default FlightTicketView;
