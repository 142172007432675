import { Route } from "react-router-dom";
import Login from "../frontend/login";
import PasswordReset from "../frontend/login/PasswordReset";
import Register from "../frontend/register";
import { OnewayTicket } from "../frontend/booking";
import HotelTicket from "../frontend/booking/HotelTicket";

export const authManages = [
      <Route path="/" exact element={<Login />} />,
      <Route path="/register" element={<Register />} />,
      <Route path="/forget-password" element={<PasswordReset />} />,
      <Route
            path="/flights/confirmation/:bookingId/pdf"
            element={<OnewayTicket />}
      />,
      <Route
            path="/hotels/confirmation/:bookingId/pdf"
            element={<HotelTicket />}
      />,
];
