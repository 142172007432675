import React, { useEffect, useState } from "react";
import "./Oneway.css";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { format } from "date-fns";
import TicketLayout from "./TicketLayout";
import { DashboardContainer } from "../dashboard/styles";

export const convertDate = (isoString) => {
      const date = new Date(isoString);
      return format(date, "dd-MMM-yyyy");
};

const OnewayTicket = () => {
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);

      const markupPrice = parseFloat(searchParams.get("markupPrice"));
      const withPriceStatus =
            searchParams.get("withPriceStatus") === "true" ? true : false;
      const withInfo = searchParams.get("withInfo") === "true" ? true : false;

      const [loading, setLoading] = useState(false);
      const { bookingId } = useParams();
      const [profile, setProfile] = useState(false);

      const [agentOrder, setAgentOrder] = useState([]);
      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [totalPriceInfo, setTotalPriceInfo] = useState([]);
      const [travellerInfos, setTravellerInfos] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      // Load booking data
      const loadData = async () => {
            try {
                  setLoading(true);
                  const response = await axios.get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/flights/${bookingId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  );

                  if (response?.data?.result === "success") {
                        setAgentOrder(response.data.agent);
                        setProfile(response.data.agentDetail);

                        const flightDetailsResponse = await axios.post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        );

                        if (flightDetailsResponse?.data?.status?.success) {
                              setOrder(flightDetailsResponse.data.order);
                              setTripInfos(
                                    flightDetailsResponse.data.itemInfos?.AIR
                                          ?.tripInfos || []
                              );
                              setTotalPriceInfo(
                                    flightDetailsResponse.data.itemInfos?.AIR
                                          ?.totalPriceInfo?.totalFareDetail ||
                                          {}
                              );
                              setTravellerInfos(
                                    flightDetailsResponse.data.itemInfos?.AIR
                                          ?.travellerInfos || []
                              );
                        }
                  }
            } catch (error) {
                  console.log(error.message);
            } finally {
                  setLoading(false);
            }
      };

      // Update booking data
      const updateData = async () => {
            if (!tripInfos.length || !travellerInfos.length) return;

            try {
                  const pnrNumber =
                        travellerInfos[0]?.pnrDetails[
                              `${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}`
                        ];

                  await axios.post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings/${bookingId}`,
                        {
                              pnr_number: pnrNumber,
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  );
            } catch (error) {
                  console.error("Error updating booking data:", error.message);
            }
      };

      useEffect(() => {
            loadData();
      }, []);

      useEffect(() => {
            updateData();
      }, [travellerInfos]);

      return (
            <>
                  <DashboardContainer
                        backgroundColor="#FFF"
                        className="p-0"
                        flexDirection="column"
                        padding="0px"
                  >
                        {!loading ? (
                              <TicketLayout
                                    bookingId={bookingId}
                                    borderShadow="none"
                                    order={order}
                                    travellerInfos={travellerInfos}
                                    currentPriceStatus={withPriceStatus}
                                    tripInfos={tripInfos}
                                    totalPriceInfo={totalPriceInfo}
                                    markupPrice={markupPrice}
                                    withInfo={withInfo}
                                    agentOrder={agentOrder}
                                    profile={profile}
                              />
                        ) : (
                              <div className="text-center">
                                    <Spinner />
                              </div>
                        )}
                  </DashboardContainer>
            </>
      );
};

export default OnewayTicket;
