import { Route } from "react-router-dom";
import FlightConfirmation from "../frontend/flight_confirmations";
import { FlightTicketView } from "../account/flights";
import FlightPaymentConfirmation from "../frontend/flight_payment_confirmations";
import FlightSearch from "../frontend/flight/FlightSearch";
import FlightReview from "../frontend/flight_reviews";
import HomePage from "../frontend/home";
import InvoiceTicket from "../account/invoices";

export const flightMange = [
      <Route path="/flight" element={<HomePage />} />,
      <Route
            path="/flights/confirmation/:bookingId"
            element={<FlightConfirmation />}
      />,
      <Route
            path="/flights/confirmation/:bookingId/view"
            element={<FlightTicketView />}
      />,
      <Route
            path="/flights/payment/confirmation/:topupId"
            element={<FlightPaymentConfirmation />}
      />,
      <Route path="/flight/search" element={<FlightSearch />} />,
      <Route path="/flight/:optionId/:reviewId" element={<FlightReview />} />,
      <Route
            path="/flights/invoice/:bookingId/view"
            element={<InvoiceTicket />}
      />,
];
