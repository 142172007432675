import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { DashboardContainer } from "../dashboard/styles";
import { Alert, Col, Row, Spinner } from "react-bootstrap";
import {
      FlightConfirmationDownload,
      FlightConfirmationHeader,
} from "../flight_confirmations/style";
import { FlightDetailContainer } from "../../components/Dashboard/FlightDetails/style";
import { handleHotelCommissionTotal } from "../../hooks/hotelCommission";
import HotelConfirmModal from "./HotelConfirmModal";
import HotelConfirmPayment from "./HotelConfirmPayment";
import HotelConfirmDetail from "./HotelConfirmDetail";
import HotelConfirmPassenger from "./HotelConfirmPassenger";

const HotelConfirmationPage = () => {
      const [loading, setLoading] = useState(false);
      const { bookingId } = useParams();

      const [agentOrder, setAgentOrder] = useState([]);
      const [profile, setProfile] = useState([]);
      const [show, setShow] = useState(false);
      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [priceInfos, setPriceInfos] = useState([]);
      const [totalAmount, setTotalAmount] = useState([]);
      const [searchQuery, setSearchQuery] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const handleTotalPrice = ({ prices, commissionsAmount }) => {
            const totalAmt = handleHotelCommissionTotal({
                  prices: prices,
                  commissionsAmount: commissionsAmount,
            });
            return totalAmt;
      };

      const loadData = async () => {
            setLoading(true);

            const response = await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/hotel/bookings/${bookingId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        toast.error(error.message);
                  });

            if (response?.data?.result === "success") {
                  setAgentOrder(response.data.agent);
                  setProfile(response.data.agentDetail);
                  await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/hotel/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.status?.success === true) {
                                    setOrder(response.data.order);
                                    setTripInfos(
                                          response.data.itemInfos?.HOTEL?.hInfo
                                    );
                                    setPriceInfos(
                                          response.data.itemInfos?.HOTEL?.hInfo
                                                ?.ops[0]
                                    );
                                    setSearchQuery(
                                          response.data.itemInfos?.HOTEL?.query
                                    );
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      useEffect(() => {
            setTotalAmount(
                  handleTotalPrice({
                        prices: priceInfos?.ris,
                        commissionsAmount: agentOrder?.commission,
                  })
            );
      }, [priceInfos, agentOrder]);

      return (
            <div>
                  <DashboardContainer flexDirection="column">
                        {!loading ? (
                              <>
                                    <Row>
                                          {show && (
                                                <Col lg={8}>
                                                      <Alert
                                                            onClose={() =>
                                                                  setShow(false)
                                                            }
                                                            dismissible
                                                            variant="success"
                                                      >
                                                            We have received
                                                            your request and
                                                            will respond shortly
                                                            to your registered
                                                            email. <br /> For
                                                            further assistance,
                                                            please contact us at{" "}
                                                            <Link to="tel:+918766601888">
                                                                  +91 8766601888
                                                            </Link>
                                                            .
                                                      </Alert>
                                                </Col>
                                          )}
                                          <Col lg={8}>
                                                <FlightConfirmationDownload>
                                                      <FlightConfirmationHeader>
                                                            <div className="confirmation__header-img">
                                                                  <img
                                                                        src="/images/booking.png"
                                                                        alt=""
                                                                  />
                                                            </div>
                                                            <div className="confirmation__header-title text-success">
                                                                  Booking
                                                                  Success
                                                                  <div className="confirmation__header-subtitle">
                                                                        Booking
                                                                        Id:{" "}
                                                                        <span>
                                                                              {
                                                                                    bookingId
                                                                              }
                                                                        </span>
                                                                  </div>
                                                            </div>
                                                      </FlightConfirmationHeader>
                                                      <div>
                                                            <HotelConfirmModal
                                                                  tripInfos={
                                                                        tripInfos
                                                                  }
                                                                  priceInfos={
                                                                        priceInfos
                                                                  }
                                                                  bookingId={
                                                                        bookingId
                                                                  }
                                                                  order={order}
                                                                  searchQuery={
                                                                        searchQuery
                                                                  }
                                                                  totalAmounts={
                                                                        totalAmount
                                                                  }
                                                                  agentOrder={
                                                                        agentOrder
                                                                  }
                                                                  profile={
                                                                        profile
                                                                  }
                                                            />
                                                      </div>
                                                </FlightConfirmationDownload>
                                                <HotelConfirmDetail
                                                      tripInfos={tripInfos}
                                                      searchQuery={searchQuery}
                                                      priceInfos={priceInfos}
                                                />
                                                <FlightDetailContainer padding="10px 20px">
                                                      <HotelConfirmPassenger
                                                            priceInfos={
                                                                  priceInfos
                                                            }
                                                            tripInfos={
                                                                  tripInfos
                                                            }
                                                            order={order}
                                                      />
                                                </FlightDetailContainer>
                                          </Col>
                                          <Col lg={4}>
                                                <HotelConfirmPayment
                                                      priceInfos={priceInfos}
                                                      agentOrder={agentOrder}
                                                      totalAmount={totalAmount}
                                                      bookingId={bookingId}
                                                      setShow={setShow}
                                                />
                                          </Col>
                                    </Row>
                              </>
                        ) : (
                              <div className="py-5 text-center">
                                    <Spinner />
                              </div>
                        )}
                  </DashboardContainer>
            </div>
      );
};

export default HotelConfirmationPage;
