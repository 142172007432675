import { Suspense, useEffect, useState } from "react";
import { Link, Routes, useLocation } from "react-router-dom";
import PublicRouter from "../router/PublicRouter";
import { GlobalStyle } from "../styles/components/globalStyle";
import "./App.css";

import { ThemeProvider } from "styled-components";
import { lightTheme } from "../styles/components/themes";

import ScrollToTop from "../components/ScrollToTop";
import PrivateLayout from "../layout/PrivateLayout";
import { Toaster } from "react-hot-toast";
import { UserContextProvider } from "../context/UserContext";
import { FrontImage } from "./style";
import { authManages } from "../router/AuthManage";

function App() {
      const [loading, setLoading] = useState(true);
      const currentLocation = useLocation().pathname;
      const [footer, setFooter] = useState(true);

      const login = localStorage.getItem("isLoggedIn");

      useEffect(() => {
            setTimeout(() => {
                  setLoading(false);
            }, 2500);
      }, [currentLocation]);

      const isPdfRoute =
            currentLocation.includes("/flights/confirmation") &&
            currentLocation.includes("/pdf");
      const isPdfHotelRoute =
            currentLocation.includes("/hotels/confirmation") &&
            currentLocation.includes("/pdf");

      return (
            <>
                  {/* <Progress.Component
				style={{ background: "#99999  978", height: "5px" }}
				thumbStyle={{
					background: "rgb(232, 107, 25)",
					height: "5px",
				}}
			/> */}
                  <UserContextProvider>
                        <ThemeProvider theme={lightTheme}>
                              <GlobalStyle />
                              {/* {loading && <Preloader />} */}
                              <ScrollToTop />
                              <Toaster position="top-right" />
                              <Suspense fallback="Loading">
                                    {login === null ? (
                                          <>
                                                {!isPdfRoute &&
                                                      !isPdfHotelRoute && (
                                                            <FrontImage>
                                                                  <Link to="/">
                                                                        <img
                                                                              src="/images/logo/logo.png"
                                                                              alt="logo"
                                                                              width="100%"
                                                                              height="100%"
                                                                        />
                                                                  </Link>
                                                            </FrontImage>
                                                      )}
                                                <Routes>{authManages}</Routes>
                                          </>
                                    ) : (
                                          <PrivateLayout footer={footer}>
                                                <PublicRouter />
                                          </PrivateLayout>
                                    )}
                              </Suspense>
                        </ThemeProvider>
                  </UserContextProvider>
            </>
      );
}

export default App;
