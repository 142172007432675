import styled from "styled-components";

export const InvoiceContainer = styled.div`
      width: 70%;
      margin: 20px auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 2px;
      font-family: Arial, sans-serif;
      background: #fff;

      .content {
            font-style: italic;
      }
      .note {
            margin-bottom: 0px;
            font-weight: 600;
            font-style: italic;
      }
`;

export const BorderContainer = styled.div`
      border: 6px solid #003366;
      border-radius: 2px;

      hr {
            border: 2px solid #868686;
            margin: 0px;
      }
`;

export const Header = styled.div`
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      background: #003366;
      color: #fff;
      padding: 10px;
`;

export const FirstSection = styled.div`
      display: flex;
      gap: 20px;
`;

export const LeftSection = styled.div`
      width: 50%;
      font-size: 14px;
      line-height: 1.5;
      border: 1px solid #ddd;
      background: white;
      padding: 10px;
`;

export const Title = styled.div`
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
`;

export const RightSection = styled.div`
      width: 50%;
      font-size: 14px;
      line-height: 1.5;
      border: 1px solid #ddd;
      background: white;
      padding: 20px;
`;

export const InfoBlock = styled.div`
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #111;
`;

export const RightInfoBlock = styled.div`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 2.5;
      font-weight: 500;
      color: #111;
`;

export const Label = styled.p`
      flex: 0 0 40%;
      margin: 0;
`;

export const Separator = styled.strong`
      flex: 0 0 5%;
      text-align: center;
`;

export const Value = styled.span`
      flex: 0 0 50%;
`;

export const SecondSection = styled.div`
      padding: 10px;
`;

export const SecondInfo = styled.div`
      border: 1px solid #ddd;
      padding: 10px;
`;

export const SecondInfoBlock = styled.div`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 1.3;
      font-weight: 500;
      color: #111;

      p {
            flex: 0 0 10%;
            margin: 0;
      }

      strong {
            flex: 0 0 2%;
            text-align: center;
      }

      span {
            flex: 0 0 50%;
      }
`;

export const Section = styled.div``;

export const Table = styled.table`
      width: 100%;
      border-collapse: collapse;
`;

export const Th = styled.th`
      background: #003366;
      color: #fff;
      padding: 10px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      border: 1px solid #ddd;
`;

export const Td = styled.td`
      padding: 8px;
      border: 1px solid #ddd;
      font-size: 14px;
      p {
            margin: 0px;
            line-height: 1.6;
      }
      h3 {
            margin-bottom: 0px;
            font-size: 14px;
      }
      .text-align {
            text-align: right;
            color: #303030;
      }
`;

export const Container = styled.div`
      margin: 10px;
      display: flex;
`;

export const BankDetails = styled.div`
      width: 70%;
      border: 1px solid #ccc;
      text-align: right;

      h3 {
            font-size: 16px;
            padding: 10px;
            margin: 0px;
            text-align: center;
            border-bottom: 1px solid #aaa9a9;
      }
`;

export const Info = styled.p`
      display: grid;
      padding: 10px;
      margin: 10px 0;
      font-weight: 600;

      span {
            font-weight: 400;
      }
`;

export const SignatureSection = styled.div`
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px;
      text-align: right;
      font-size: 14px;
`;
