import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import {
      FlightDetailContainer,
      FlightDetailInfo,
} from "../../components/Dashboard/FlightDetails/style";
import {
      FlightFooterButton,
      FlightReviewHeader,
      FlightReviewTable,
} from "../../components/Frontend/flight_reviews/FlightReviewDetail/style";
import TravellerReviewDetail from "../../components/Frontend/flight_reviews/FlightReviewDetail/TravellerReviewDetail";
import CommonButton from "../../components/Common/CommonButton";
import { DoubleIcon } from "../../icons";
import axios from "axios";
import toast from "react-hot-toast";
import { convertFloat } from "../../hooks/CurrentData";
import HotelReviewPayment from "./HotelReviewPayment";
import { handleHotelCommissionOnly } from "../../hooks/hotelCommission";

const HotelReviewDetail = ({
      hotelId,
      hOptionId,
      bookingId,
      totalAmounts,
      hotelInfo,
      priceInfo,
}) => {
      const userCtx = useContext(UserContext);
      const paymentStatus = localStorage.getItem("hotelPaymentStatus");

      const [loading, setLoading] = useState(false);
      const [loadingBack, setLoadingBack] = useState(false);
      const navigate = useNavigate();

      const [totalAmount, setTotalAmount] = useState(totalAmounts || 0);
      const [totalNetAmount, setTotalNetAmount] = useState(0);
      const [walletStatus, setWalletStatus] = useState(false);
      const [payableAmount, setPayableAmount] = useState(totalAmounts || 0);

      const token = JSON.parse(localStorage.getItem("token"));

      const travellers = JSON.parse(localStorage.getItem("hotelTravellers"));
      const contactDetails = JSON.parse(localStorage.getItem("contactDetails"));

      const handleBack = () => {
            setLoadingBack(true);

            setTimeout(() => {
                  setLoadingBack(false);

                  navigate(`/hotel/travellers/${hotelId}/${hOptionId}`);
            }, 3000);
      };

      const handlePayment = async () => {
            const callbackUrl = window.location.href;
            const redirectUrl =
                  window.location.origin + `/hotels/payment/confirmation`;

            const paymentData = {
                  callbackUrl: callbackUrl,
                  redirectUrl: redirectUrl,
                  amount: convertFloat(payableAmount),
                  reviewId: `${hotelId},${hOptionId}`,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/phonePe`,
                        paymentData,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        const data = {
                              reviewId: hotelId,
                              payableAmount: convertFloat(payableAmount),
                        };
                        localStorage.setItem(
                              "hotelPayableAmt",
                              JSON.stringify(data)
                        );
                        const redirectInfoUrl =
                              response?.data?.data?.instrumentResponse
                                    ?.redirectInfo?.url;
                        setLoading(false);
                        window.location.href = redirectInfoUrl;
                  })
                  .catch((error) => {
                        console.log(error.message);
                        setLoading(false);
                  });
      };

      const handleOption = async () => {
            setLoading(true);
            let travellerDetails;
            if (payableAmount === 0) {
                  if (!(userCtx?.profile?.balance >= totalNetAmount)) {
                        toast.error("Insufficient Balance");
                        setLoading(false);
                        return;
                  }
                  if (travellers) {
                        travellerDetails = travellers.map(
                              (room, roomIndex) => ({
                                    travellerInfo: room.travellerInfo.map(
                                          (traveller) => ({
                                                fN: traveller.firstName || "",
                                                lN: traveller.lastName || "",
                                                ti: traveller.title || "",
                                                pt: traveller.pt || "",
                                                pan: traveller.pan || "", // Assuming PAN corresponds to passportNumber
                                          })
                                    ),
                              })
                        );
                  }

                  const data = {
                        bookingId: bookingId,
                        type: "HOTEL",
                        roomTravellerInfo: travellerDetails,
                        paymentInfos: [
                              {
                                    amount: priceInfo?.tp,
                              },
                        ],
                        deliveryInfo: {
                              emails: [contactDetails?.emailAddress],
                              contacts: [contactDetails?.phoneNumber],
                              code: [contactDetails?.countryCode],
                        },
                  };
                  const response = await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/hotel/book`,
                              data,
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .catch((error) => {
                              toast.error(error.message);
                        });
                  if (response?.data) {
                        const bookingData = {
                              booking_id: bookingId,
                              name: `${travellers[0]?.travellerInfo[0]?.title} ${travellers[0]?.travellerInfo[0]?.firstName} ${travellers[0]?.travellerInfo[0]?.lastName}`,
                              email: contactDetails?.email,
                              summary: JSON.stringify(travellerDetails),
                              amount: totalAmount,
                              totalNetAmount: priceInfo?.tp,
                              order_type: "Hotel",
                              status: "Success",
                              payableAmount: payableAmount,
                              commission: handleHotelCommissionOnly({
                                    commissions:
                                          userCtx?.profile?.hotelCommission,
                                    domesticStatus: true,
                                    hotelRating: hotelInfo?.rt,
                              }),
                              paymentStatus:
                                    payableAmount === 0 ? "Success" : "Pending",
                        };
                        if (response.data.status?.success === true) {
                              await axios
                                    .post(
                                          `${process.env.REACT_APP_SECRET_KEY}/api/agent/hotel/bookings`,
                                          bookingData,
                                          {
                                                headers: {
                                                      Authorization: `Bearer ${token}`,
                                                },
                                          }
                                    )
                                    .then((response) => {
                                          if (
                                                response.data.result ===
                                                "success"
                                          ) {
                                                setLoading(false);
                                                if (payableAmount === 0) {
                                                      navigate(
                                                            `/hotels/confirmation/${response?.data?.bookingId}`
                                                      );
                                                }
                                          }
                                    })
                                    .catch((error) => {
                                          toast.error(error.message);
                                          setLoading(false);
                                    });
                        }
                  }
            } else {
                  setTimeout(() => {
                        handlePayment();
                        setLoading(false);
                  }, 3000);
            }
      };

      const [show, setShow] = useState("");

      useEffect(() => {
            setTotalAmount(totalAmounts);
            setPayableAmount(totalAmounts);
      }, [userCtx, priceInfo]);

      useEffect(() => {
            if (paymentStatus) {
                  setShow(true);
            }
      }, [paymentStatus]);

      return (
            <>
                  {show && (
                        <Alert
                              variant="danger"
                              onClose={() => setShow(false)}
                              dismissible
                        >
                              <Alert.Heading>
                                    Oh snap! You got an error!
                              </Alert.Heading>
                              <p>
                                    We regret to inform you that the payment for
                                    your flight booking attempt was
                                    unsuccessful.
                              </p>
                        </Alert>
                  )}
                  <FlightDetailContainer className="mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                              <FlightReviewHeader>
                                    <div>Travellers Details</div>
                              </FlightReviewHeader>
                              <TravellerReviewDetail />
                        </div>
                        <FlightDetailInfo
                              marginBottom="0"
                              paddingBottom="0"
                              className="mt-0"
                              border="0"
                        >
                              {travellers?.length > 0 &&
                                    travellers?.map((rooms, roomIndex) =>
                                          rooms?.travellerInfo?.map(
                                                (item, index) => (
                                                      <FlightReviewTable
                                                            border={false}
                                                            key={index}
                                                      >
                                                            <div>
                                                                  {++roomIndex}.{" "}
                                                            </div>
                                                            <div>
                                                                  {`${item?.title} ${item?.firstName} ${item?.lastName}`}
                                                            </div>
                                                      </FlightReviewTable>
                                                )
                                          )
                                    )}
                        </FlightDetailInfo>
                  </FlightDetailContainer>

                  <HotelReviewPayment
                        totalAmount={totalAmount}
                        setWalletStatus={setWalletStatus}
                        setPayableAmount={setPayableAmount}
                        payableAmount={payableAmount}
                  />

                  <FlightFooterButton className="price__button  mt-4  mt-0">
                        <CommonButton
                              title={"Back"}
                              leftIcon={
                                    <DoubleIcon
                                          transform="rotate(180deg)"
                                          color="#FFF"
                                    />
                              }
                              padding={"8px 16px"}
                              type="button"
                              button={true}
                              handleClick={handleBack}
                              loading={loadingBack}
                        />
                        <CommonButton
                              title={"Pay Now"}
                              icon={<DoubleIcon color="#FFF" />}
                              padding={"8px 30px"}
                              type="submit"
                              button={true}
                              handleClick={handleOption}
                              loading={loading}
                        />
                  </FlightFooterButton>
            </>
      );
};

export default HotelReviewDetail;
