import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../frontend/login";
import HomePage from "../frontend/home";
import PackagesPage from "../frontend/packages";
import PackageDetail from "../frontend/package_details";
import PrivacyPolicy from "../frontend/privacy";
import TermsAndConditions from "../frontend/terms";
import BaggagePolicy from "../frontend/baggage-policy";
import RefundPolicy from "../frontend/refund-policy";
import { accountManages } from "./AccountManage";
import PaymentConfirmationPage from "../frontend/payment_confirmations/PaymentConfirmationPage";
import { flightMange } from "./flightMange";
import { hotelMange } from "./hotelMange";

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/" exact element={<HomePage />} />
                        {/* Hotel */}
                        {hotelMange}

                        {/* Package */}
                        <Route path="/packages" element={<PackagesPage />} />
                        <Route
                              path="/packages/:packageId"
                              element={<PackageDetail />}
                        />

                        <Route
                              path="/payments/confirmation/:topupId"
                              element={<PaymentConfirmationPage />}
                        />

                        {/* Privacy Policy */}
                        <Route
                              path="/privacy-policy"
                              element={<PrivacyPolicy />}
                        />

                        {/* Terms & Condition */}
                        <Route
                              path="/terms-and-conditions"
                              element={<TermsAndConditions />}
                        />

                        {/* Refund Policy */}
                        <Route
                              path="/refund-policy"
                              element={<RefundPolicy />}
                        />

                        {/* Baggage Policy */}
                        <Route
                              path="/baggage-policy"
                              element={<BaggagePolicy />}
                        />

                        {/* Account Manage */}
                        {accountManages}

                        {/* Flight Mange */}
                        {flightMange}

                        <Route path="/login" element={<Login />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
